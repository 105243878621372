<template>
  <div class="main">
    <div class="fixed-back">
      <div id="header" class="header">
        <Header />
      </div>
      <div class="signup">
        <div class="container">
          <div class="row d-flex justify-content-center">
            <div class="col-md-6 mt-3">
              <h2 class="fw-light text-light">Easily book with the app.</h2>
              <p class="fw-light text-light">
                Enjoy discounts, promos and free trips
              </p>
            </div>
            <div class="pr-2 col-md-3 mt-3">
              <a class="btn btn-light signup-btn fw-light align-self-center" href="/driver">Become a driver</a>
            </div>
          </div>
        </div>
      </div>

      <div class="p-10" id="download-section">
        <Download />
      </div>

      <div class="signup" style="margin-top: 20px;">
        <div class="container">
          <div class="row d-flex justify-content-center">
            <div class="col-md-6 mt-3">
              <h2 class="fw-light text-light">We at Vite respect your privacy and your right to control your data.</h2>
            </div>
            <div class="pr-2 col-md-3 mt-3">
              <a class="btn btn-light signup-btn fw-light align-self-center" href="/data-privacy">Learn more</a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
const Header = () => import("./Header.vue");
const Download = () => import("./Download.vue");
// const Testimonial = () => import("./Testimonial.vue");
// import Slick from "vue-slick";
import "slick-carousel/slick/slick.css";
export default {
  components: {
    Header,
    Download,
    // Slick,
    // Testimonial,
  },
  data() {
    return {
      slickOptions: {
        dots: false,
        infinite: true,
        speed: 300,
        centerMode: false,
        variableWidth: true,
        autoplay: true,
        slidesToShow: 2,
        prevArrow: false,
        nextArrow: false,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  methods: {
    upPage() {
      document.getElementById("deliver-item").click();
      window.scrollTo(0, 0);
    },
    gotoWhatsapp() {
      window.open('https://api.whatsapp.com/send/?phone=23059150708&text&app_absent=0', 'focus')
    },
    gotoDownload() {
      this.$router.push('/downloads')
    },
    gotoTaxi() {
      document.getElementById("taxi-ride").click();
      window.scrollTo(0, 0);
    },
    gotoDeliver() {
      document.getElementById("deliver-item").click();
      window.scrollTo(0, 0);
    }

  },
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        if (this.$route.query.q === "apps") {
          console.log("scrolling");
          window.scrollTo(0, document.body.scrollHeight);
        }
      }
    };
  },
};
</script>

<style scoped>
.fixed-plugin {
  position: sticky;
  display: inline;
  width: 125px;
  z-index: 101;
  top: 200px;
  left: 0;
}

.fixed-icons i {
  font-size: 50px;
}

.google-icon {
  width: 50px;
}

.whatsapp-icon {
  width: 40px;
  margin-left: 8px;
  margin-bottom: 5px;
}

.huawei-icon {
  width: 45px;
}

.logo-peace {
  width: 75px;
}

.w-50-per {
  width: 50%;
}

.w-40-per {
  width: 40%;
}

.card {
  border: none;
  border-radius: 12px;
  -webkit-box-shadow: 0 6px 10px 0 rgb(0 0 0 / 15%);
  box-shadow: 0 6px 10px 0 rgb(0 0 0 / 15%);
}

.bg-green {
  background-color: #18b775;
}

.img-bg {
  height: 600px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../assets/imgs/header-img.png");
}

.header-bg-transparent {
  background-color: #0c0c0c9f;
  height: 100%;
  width: 100%;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.img-bg .header-bg-transparent {
  border-radius: 0;
}

.below-tes-text {
  position: absolute;
  top: 200px;
}

.below-tes-text h3 {
  font-size: 58px;
}

.newsletter {
  background-color: #222;
  padding-bottom: 50px;
}

.font-81 {
  font-size: 81px;
}

.newsletter-input {
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #fff;
  border-radius: 0 !important;
  color: #fff;
}

.newsletter-input::placeholder {
  color: #fff;
}

.newsletter-input:focus {
  border: none;
  background-color: transparent;
  box-shadow: none;
  color: #fff;
  border-bottom: 1px solid #fff;
}

.newsletter-form .el-button.el-button--plain {
  color: #fff;
  width: 75px;
  background-color: transparent;
}

.footer {
  background-color: #222;
  color: #ffffff;
}

.footer-google-img {
  width: 125px;
}

#header {
  margin-top: 175px;
}

.signup {
  background-color: #4f5cd1 !important;
  min-height: 200px;
  padding: 40px;
  width: 100%;
}

.signup-btn {
  padding: 17px 23px;
  border-radius: 30px;
  font-size: 1rem;
  font-weight: 600;
}

.image-size {
  display: block;
  margin: auto;
  height: 180px;
  border-radius: 15px;
}

.font-weight {
  font-weight: bold;
  font-size: 27px;
}

.text-align {
  display: block;
  margin: auto;
}

.position {
  position: absolute;
  top: 170px;
  left: 130px;
}

@media screen and (max-width: 991px) {
  .w-50-per {
    width: 80%;
  }

  .font-81 {
    font-size: 50px;
  }

  .below-tes-text h3 {
    font-size: 44px;
  }

  .w-40-per {
    width: 70%;
  }
}

@media screen and (max-width: 786px) {
  #header {
    margin-top: 0;
  }

  .position {
    position: absolute;
    top: 170px;
    left: 140px;
  }
}

@media screen and (max-width: 763px) {
  .position {
    position: absolute;
    top: 170px;
    left: 200px;
  }
}

@media screen and (max-width: 688px) {
  .position {
    position: absolute;
    top: 170px;
    left: 200px;
  }
}

@media screen and (max-width: 667px) {
  .position {
    position: absolute;
    top: 170px;
    left: 120px;
  }

  .signup {
    display: block;
    margin: auto;
    max-width: 92%;
  }
}

/* @media screen and (max-width: 997px) {

  .position{
  position: absolute;
  top: 170px;
  left: 170px;

}
} */
</style>
